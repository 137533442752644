import {
  FC,
  JSXElementConstructor,
  ReactElement,
  SetStateAction,
  useState,
} from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  refundBooking,
  updateBookings,
  updateOswBookings,
  updateOpenRefunds,
  updateMusementBookings,
  updateMxpBookings,
} from "../../actions/cartActions";
import CenteredImageContainer from "../CenteredImageContainer";
import { getResourceImageUrl } from "utils/imageUtils";
import SvgIcon from "../atoms/SvgIcon";
import Modal from "../modals/Modal/Modal";
import TextInputWithLabel from "../TextInputWithLabel";
import ModalFooter from "../modals/ModalFooter";
import useAppSelector from "../../hooks/useAppSelector";
import { LocationDescriptor } from "history";
import { useGetExcursionImages } from "modules/excursions/hooks/useGetExcursionImages";

type Props = {
  refundIds: any;
  refundDisabled?: any;
  entrySessionType?: any;
  name: string;
  imageReference: any;
  imageCategory?: any;
  linkTo: string | LocationDescriptor;
  children: any;
  tooltipText: string;
  tooltipId: string | string[];
  modalContent: ReactElement<any, string | JSXElementConstructor<any>>;
};
const CartElementBooking: FC<Props> = (props) => {
  const [showRefundModal, setShowRefundModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [refundInProgress, setRefundInProgress] = useState<boolean>(false);
  const [refundReference, setRefundReference] = useState("");
  const { getExcursionImageThumbnail } = useGetExcursionImages({
    excursionId: props?.imageReference,
  });

  const sessionType = useAppSelector(
    (state) => state.authentication.sessionType
  );
  const isAgency = sessionType === "SingleSignOn";

  const processRefund = async () => {
    if (refundInProgress) {
      return;
    }

    setRefundInProgress(true);

    await refundBooking(props.refundIds, refundReference);

    setShowRefundModal(false);
    setRefundInProgress(false);
    setShowConfirmationModal(false);
    setRefundReference("");

    await updateBookings();
    await updateOswBookings();
    await updateMusementBookings();
    await updateMxpBookings();
    await updateOpenRefunds();
  };

  const handleRefundClick = () => {
    if (isAgency) {
      processRefund();
    } else {
      setShowRefundModal(false);
      setShowConfirmationModal(true);
    }
  };

  const isButtonDisabled = () => {
    // parent component can disable refund button first
    if (props.refundDisabled) {
      return true;
    }

    // refunds can only happen on the same "channel" (only applies to TAC, excursions have per pax check)
    if (props.entrySessionType && sessionType !== props.entrySessionType) {
      return true;
    }

    // agency needs to provide refund reference
    if (isAgency) {
      return !refundReference;
    } else {
      return false;
    }
  };

  return (
    <div>
      <div className="flex flex-wrap bg-blue-lightest shadow sm:p-4 my-4">
        {/* image */}
        <div className="w-full sm:w-1/2 md:w-2/5 xl:w-1/3">
          <CenteredImageContainer
            alt={props.name + " cover"}
            src={getExcursionImageThumbnail}
          />
        </div>

        {/* rest of card */}
        <div className="sm:flex sm:flex-col px-4 py-2 sm:p-0 w-full sm:w-1/2 md:w-3/5 xl:w-2/3 sm:pl-4 mt-2 sm:mt-0 relative">
          {/* Cancel the booking - show refund modal */}
          <span
            className="text-blue cursor-pointer absolute top-0 right-0 mr-4 mt-2 sm:m-0"
            onClick={() => {
              setShowRefundModal(true);
            }}
          >
            <span className="hidden md:inline">Cancel</span>{" "}
            <SvgIcon name="x" className="w-3 h-3 inline ml-1" />
          </span>

          {/* title link */}
          <div className="mr-6 md:mr-16">
            <Link
              className="text-blue underline text-2xl uppercase sm:pr-3 md:pr-0"
              to={props.linkTo}
            >
              {props.name}
            </Link>
          </div>

          {/* details */}
          <div className="sm:flex-grow">
            <div className="my-2">{props.children}</div>
          </div>
          {/* cost info */}
          <div className="text-right">
            Already purchased -
            <span
              className="cursor-pointer ml-2 text-blue border-dotted border-b border-blue"
              data-tip={props.tooltipText}
              data-for={props.tooltipId}
              data-effect="solid"
              data-place="bottom"
              data-multiline={true}
            >
              See cost
            </span>
          </div>

          <ReactTooltip id={props.tooltipId as string} />
        </div>
      </div>

      {/* Refund modal */}
      <Modal
        show={showRefundModal}
        onClose={() => {
          setShowRefundModal(false);
        }}
        title="Cancel booking"
      >
        <div className="flex flex-grow flex-col">
          <div className="flex-grow bg-white py-4 px-2 sm:px-4">
            <div>
              {props.modalContent}

              {isAgency && (
                <div className="my-4">
                  <TextInputWithLabel
                    label="Refund Reference"
                    type="text"
                    value={refundReference}
                    _onChange={(value: SetStateAction<string>) =>
                      setRefundReference(value)
                    }
                    placeholder=""
                    errorMessage=""
                  />
                </div>
              )}
            </div>

            {props.entrySessionType &&
              props.entrySessionType !== sessionType && (
                <div className="text-lg font-bold py-4">
                  Bookings can only be refunded via the same channel they were
                  booked
                </div>
              )}
          </div>
          <ModalFooter
            onCancel={() => {
              setShowRefundModal(false);
            }}
            actionDisabled={isButtonDisabled() || refundInProgress}
            actionButtonText={
              refundInProgress ? "Please wait..." : "Refund booking"
            }
            onActionClick={handleRefundClick}
          />
        </div>
      </Modal>

      {/* Confirmation modal */}
      <Modal
        show={showConfirmationModal}
        onClose={() => {
          setShowConfirmationModal(false);
        }}
        title="Confirmation"
      >
        <div className="flex flex-grow flex-col">
          <div className="flex-grow bg-white py-4 px-2 sm:px-4">
            <div className="text-xl font-bold">
              Are you sure you want to cancel this booking?
            </div>
          </div>

          <ModalFooter
            cancelLabel="No"
            onCancel={() => {
              setShowConfirmationModal(false);
            }}
            actionDisabled={refundInProgress}
            actionButtonText={
              refundInProgress ? "Please wait..." : "Yes - cancel"
            }
            onActionClick={processRefund}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CartElementBooking;
