import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import useAppSelector from "hooks/useAppSelector";
import { getStorageUrl } from "config/configUtils";
import tourImageFallback from "resources/images/tour_image_coming_soon.png";

type Props = {
  excursionId?: string | null;
};

export const useGetExcursionImages = ({ excursionId }: Props) => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);

  const storageUrl = `${getStorageUrl()}api/v1/items?itemId=${excursionId}`;

  const URL = storageUrl;

  const { status, data, error, isFetching, isSuccess } = useQuery({
    queryKey: ["useGetExcursionImages", excursionId],
    queryFn: async () =>
      await axios
        .get(URL, {
          headers: {
            SessionId: sessionId,
          },
        })
        .then((res) => res.data as Promise<any>),
    refetchOnWindowFocus: false,
    enabled: !!excursionId,
  });

  return {
    getExcursionImagesData:
      data?.map((image: any) => ({
        thumbnail: `${image?.itemPublicUrl}`,
        original: `${image?.itemPublicUrl}`,
      })) || [],
    getExcursionImageThumbnail: data?.[0]?.itemPublicUrl ?? tourImageFallback,
    getExcursionImagesStatus: status,
    getExcursionImagesError: error,
    getExcursionImagesIsFething: isFetching,
    getExcursionImagesIsSuccess: isSuccess,
  };
};
