import { useEffect, useState } from "react";
import Price from "components/Price/Price";
import ContentHeaderImage from "components/ContentHeaderImage";
import GalleryToggleButton from "components/GalleryToggleButton";
import SuccessModal from "components/modals/SuccessModal";
import ErrorModal from "components/modals/ErrorModal";
import { FC } from "react";
import MxpRestaurantAvailabilityModal from "modules/mxp/dining/MxpRestaurantAvailabilityModal";
import { IMxpRestaurant } from "interfaces/MXP/IMxpRestaurant";
import { sanitizeHTML } from "utils/sanitizeHTML/sanitizeHTML";
import InfoBox from "components/passengerInformation/InfoBox/InfoBox";

const RESTAURANTS_LIST_CHILDREN_FULL_PRICE = [
  "Kora La",
  "Surf & Turf",
  "Umi Sushi",
];

type Props = {
  preselectedDetails: any;
  restaurant: IMxpRestaurant;
  date: any;
  toggleGallery: (value: any) => void;
  isBookingEnabled: any;
  descriptions: any;
};

const MxpRestaurantEntry: FC<Props> = ({
  preselectedDetails,
  restaurant,
  date,
  toggleGallery,
  isBookingEnabled,
  descriptions,
}) => {
  const showChildrenPriceMessage =
    RESTAURANTS_LIST_CHILDREN_FULL_PRICE.includes(restaurant.venueName);
  const isRestaurantPreselected =
    preselectedDetails &&
    preselectedDetails.serviceProfileId === restaurant.venueId;

  const [modalType, setModalType] = useState(
    isRestaurantPreselected ? "availability" : ""
  );
  const [errorMessage, setErrorMessage] = useState("");

  const showModal = (modalName: string) => {
    document.body.style.overflow = "hidden";

    setModalType(modalName);
  };

  const hideModal = (modalName: string) => {
    document.body.style.overflow = "unset";

    setModalType("");
  };

  const showError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    showModal("error");
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div className="bg-white border border-blue-light my-4">
      {showChildrenPriceMessage && (
        <InfoBox
          twBackgroundColorClass="bg-blue-lightest"
          classes="flex w-full flex-row p-2 sm:p-4"
        >
          <div className="flex ml-4">
            Children joining us in our speciality restaurants are charged the
            full price. If they choose the children’s menu once they’re onboard,
            we’ll refund their speciality meal cost.
          </div>
        </InfoBox>
      )}

      <div className="md:flex md:flex-wrap xl:flex-nowrap">
        {/*image*/}
        <div className="w-full sm:p-4 md:w-1/2 xl:w-1/3">
          <ContentHeaderImage
            altText={restaurant.venueName + " - header"}
            resourceId={restaurant?.imageUrl ?? undefined}
            width={400}
          >
            <GalleryToggleButton
              toggleGallery={() => toggleGallery(restaurant.venueId)}
            />
          </ContentHeaderImage>
        </div>

        <div className="p-4 md:w-1/2 md:pl-0 xl:w-1/2 xl:flex-grow sm:pt-0 md:pt-4">
          {!isBookingEnabled && (
            <div className="text-3xl text-blue uppercase">
              {restaurant.venueName}
            </div>
          )}

          {isBookingEnabled && (
            <button
              className="text-3xl text-blue underline uppercase"
              onClick={() => showModal("availability")}
            >
              {restaurant.venueName}
            </button>
          )}

          {/* <ul className="list-square list-inside my-2">
            <li>
              Open {restaurant.fromTime} - {restaurant.toTime},{" "}
              {restaurant.location}
            </li>
          </ul> */}

          <div>
            {restaurant?.description && (
              <div
                dangerouslySetInnerHTML={sanitizeHTML({
                  html: restaurant.description,
                })}
              />
            )}
          </div>
        </div>

        <div className="w-full xl:w-1/5 px-4 xl:p-4">
          <div className="bg-blue-lightest-25 p-4 w-full leading-normal mb-4 sm:flex xl:flex-col xl:mb-0">
            <div className="sm:flex-grow">
              <div>{descriptions && descriptions.included}</div>
              {/* {restaurant.pdfDownloadAllowed && (
                <div>
                  <a
                    href={`${getHubBaseUrl()}blobs/pdf?reference=${
                      restaurant.id
                    }`}
                    className="underline text-blue"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    See example menu
                  </a>
                </div>
              )} */}
            </div>
            <div className="text-right leading-none xl:mt-3">
              <Price
                preText="from"
                price={String(restaurant.pricePerPerson)}
                postText=" pp"
              />
              {!isBookingEnabled && (
                <div className="mt-2 text-sm text-left">
                  We’ll contact you when this is available to book
                </div>
              )}

              {isBookingEnabled && (
                <button
                  className="action-button action-button-enabled w-full mt-3"
                  onClick={() => showModal("availability")}
                >
                  BOOK NOW
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {modalType === "availability" && (
        <MxpRestaurantAvailabilityModal
          restaurant={restaurant}
          date={date}
          closeModal={() => hideModal("availability")}
          onSuccess={() => showModal("success")}
          onError={(errorMessage: any) => showError(errorMessage)}
        />
      )}

      <SuccessModal
        show={modalType === "success"}
        onClose={() => hideModal("success")}
        title="Restaurant reservation"
        message="Restaurant reservation added to your planner"
      />

      <ErrorModal
        show={modalType === "error"}
        onClose={() => hideModal("error")}
        title="Error"
        message={errorMessage}
      />
    </div>
  );
};

export default MxpRestaurantEntry;
