import { IMxpAvailabilitySlot } from "interfaces/MXP/IMxpAvailabilitySlot";
import { FC } from "react";
import { priceWithTwoDecimals } from "utils/cartUtils";

type MxpAvailabilityOptionProps = {
  availability: IMxpAvailabilitySlot;
  isSelected: boolean;
  isDisabled: boolean;
  isDisabledClick: boolean;
  onClick: () => void;
  onDisabledClick: () => void;
};

const MxpAvailabilityOption: FC<MxpAvailabilityOptionProps> = ({
  availability,
  isSelected,
  isDisabled,
  isDisabledClick,
  onClick,
  onDisabledClick,
}) => {
  let classes =
    "relative p-4 border shadow flex md:flex-col border-blue select-none";

  if (isDisabled) {
    classes += " bg-grey-light cursor-not-allowed";
  } else {
    if (isSelected) {
      classes += " bg-blue text-white";
    } else {
      classes += " cursor-pointer text-grey-darker hover:bg-blue-lightest";
    }
  }

  const handleClick = () => {
    if (isDisabledClick && onDisabledClick) {
      onDisabledClick();
    } else {
      !isDisabled && onClick();
    }
  };

  return (
    <div className="md:px-2 pb-4  md:w-1/4 text-center">
      <div className={classes} onClick={handleClick}>
        {isSelected && (
          <div className="diagonal-blue w-6 h-6 absolute top-0 right-0 text-white text-xs text-right">
            &#x2714;
          </div>
        )}

        <div className={`font-bold mr-2 ${isSelected ? "" : "text-blue"}`}>
          {availability.AvailableTime}
        </div>
        <div>{`Total Cost: ${priceWithTwoDecimals(
          availability.totalAmount?.toString()
        )}`}</div>

        <div className="md:hidden mr-1">,</div>
      </div>
    </div>
  );
};

export default MxpAvailabilityOption;
