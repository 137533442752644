import { IMxpAvailabilitySlot } from "interfaces/MXP/IMxpAvailabilitySlot";
import { IMxpDiningCartItems } from "interfaces/MXP/IMxpDiningCartItems";

export interface IMxpAvailabilitySlotWithStatus extends IMxpAvailabilitySlot {
  disabledForBooking?: boolean;
}

/**
 * Marks slots in availabilitySlots as disabledForBooking if they fall within ±2h of any cart item.
 *
 * @param availabilitySlots - All possible slots for booking.
 * @param mxpCartItemsOnCurrentDate - Slots already booked by the user.
 * @returns Updated availabilitySlots with `disabledForBooking: true` where needed.
 */
export function markDisabledSlots(
  availabilitySlots: IMxpAvailabilitySlot[],
  mxpCartItemsOnCurrentDate: Pick<IMxpDiningCartItems, "AvailableTime">[]
): IMxpAvailabilitySlotWithStatus[] {
  if (mxpCartItemsOnCurrentDate?.length === 0) {
    return availabilitySlots;
  }

  const pad = (n: number) => String(n).padStart(2, "0");

  const getTimeStr = (h: number, m: number): string => `${pad(h)}:${pad(m)}`;

  const getBounds = (time: string): { from: string; to: string } => {
    const [hour, min] = time.split(":").map(Number);
    const lower = getTimeStr(hour - 2, min);
    const upper = getTimeStr(hour + 2, min);
    return { from: lower, to: upper };
  };

  const disabledTimeSet = new Set<string>();

  for (const cartItem of mxpCartItemsOnCurrentDate) {
    const { from, to } = getBounds(cartItem.AvailableTime);

    for (const slot of availabilitySlots) {
      const t = slot.AvailableTime;
      if (t > from && t < to) {
        disabledTimeSet.add(t);
      }
    }
  }

  return availabilitySlots.map((slot) =>
    disabledTimeSet.has(slot.AvailableTime)
      ? { ...slot, disabledForBooking: true }
      : slot
  );
}
